<template>
  <farmer-details>
    <template #heading>Partner Payments</template>
    <template #content>
      <div>
        <h5>Total Order Amount : {{amount.totalOrder}}</h5>
        <h5>Total Amount Paid: {{amount.totalPayment}}</h5>
      </div>
      <div class="table-responsive">
        <table class="table table-striped">
          <thead>
            <tr>
              <th scope="col">Date</th>
              <th scope="col">Amount</th>
              <th scope="col">Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(payment) in payments" :key="payment">
              <td>{{ payment.paymentDate }}</td>
              <td>{{ payment.totalAmount }}</td>
              <td>
                <button type="button" class="btn btn-sm btn-primary" @click="sendSms(payment)">
                  Send SMS
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <transaction-number-entry-dialog :showHide="showHideTrans" :payment="selectedPayment" :retailerId="retailerId"
        @smsSent="sentSMS" @closed="transactionDialogClosed()" />
    </template>
    
  </farmer-details>
</template>

<script>
import farmerDetails from "@/layouts/retailerDetails.vue";
import { mapActions } from "vuex";
import moment from 'moment'
import { useToast } from "vue-toastification";
import transactionNumberEntryDialog from '@/components/common/TransactionNumberEntryDialog.vue'

export default {
  name: "RetailerSalesView",
  components: {
    farmerDetails,
    transactionNumberEntryDialog
  },
  setup() {
    return {
      toast: useToast()
    }
  },
  data() {
    return {
      retailerId: this.$route.params.rid,
      payments: [],
      showHideTrans: false,
      selectedPayment: null,
      amount: {
        totalOrder: '-',
        totalPayment: '-'
      }
    };
  },
  computed: {
  },
  methods: {
    ...mapActions(["getPaymentsByDateRetailer", "getRetailerOrderAmount", "sendRetailerPaymentSMS"]),
    transactionDialogClosed() {
      this.showHideTrans = false;
      this.selectedPayment = null;
    },
    async sendSms(payment) {
      this.selectedPayment = payment;
      this.showHideTrans = true;
    },
    sentSMS(payload) {
      this.showHideTrans = false;
      this.selectedPayment = null;

      if (payload.success) {
        this.toast.success("Payment SMS sent");
      } else {
        this.toast.error("Failed to send Payment SMS");
      } 
    }
  },
  async mounted() {
    let response = await this.getPaymentsByDateRetailer({
      retailerId: this.retailerId
    })
    console.log(response)
    this.payments = response.data.payments
    this.payments.forEach(p => {
      p.paymentDate = moment(p.paymentDate).format('DD-MM-YYYY')
    })
    let response2 = await this.getRetailerOrderAmount({
      retailerId: this.retailerId
    })
    if (response2.success) {
      this.amount = response2.data.amount
    }
    console.log(response2)    
  },
};
</script>

<style scoped>
.item-key {
  min-width: 200px;
}

.item-value {
  min-width: 400px;
}
</style>