<template>
    <div ref="addTraderDialog" class="modal fade" data-bs-backdrop="static" id="addTraderDialog"
        aria-labelledby="exampleModalLabel" role="dialog">
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Add Distributor</h5>
                    <button type="button" class="btn-close" @click="closeTrader()" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <form>
                        <div class="form-floating fs-top mb-3">
                            <input id="fname" type="text" class="form-control" placeholder="FirstName"
                                v-model="newTrader.firstName" @focus="v$.newTrader.firstName.$reset()" />
                            <label for="fname">First Name</label>
                            <div v-if="v$.newTrader.firstName.$error" class="error-message mb-2">
                                <p v-for="error of v$.newTrader.firstName.$errors" :key="error.$uid">
                                    <strong>{{ error.$message }}</strong>
                                </p>
                            </div>
                        </div>

                        <div class="form-floating fs-middle mb-3">
                            <input id="lname" type="text" class="form-control" placeholder="LastName"
                                v-model="newTrader.lastName" @focus="v$.newTrader.lastName.$reset()" />
                            <label for="lname">Last Name</label>
                            <div v-if="v$.newTrader.lastName.$error" class="error-message mb-2">
                                <p v-for="error of v$.newTrader.lastName.$errors" :key="error.$uid">
                                    <strong>{{ error.$message }}</strong>
                                </p>
                            </div>
                        </div>

                        <div class="form-floating fs-middle mb-3">
                            <input id="entityName" type="text" class="form-control" placeholder="Entity"
                                v-model="newTrader.entityName" @focus="v$.newTrader.entityName.$reset()" />
                            <label for="entityName">Entity Name</label>
                            <div v-if="v$.newTrader.entityName.$error" class="error-message mb-2">
                                <p v-for="error of v$.newTrader.entityName.$errors" :key="error.$uid">
                                    <strong>{{ error.$message }}</strong>
                                </p>
                            </div>
                        </div>

                        <div class="form-floating fs-middle mb-3">
                            <input id="phone" type="text" class="form-control" placeholder="9889898898"
                                v-model="newTrader.phone" @focus="v$.newTrader.phone.$reset()" maxlength="10" />
                            <label for="phone">Phone Number</label>
                            <div v-if="v$.newTrader.phone.$error" class="error-message mb-2">
                                <p v-for="error of v$.newTrader.phone.$errors" :key="error.$uid">
                                    <strong>{{ error.$message }}</strong>
                                </p>
                            </div>
                        </div>

                        <div class="form-floating fs-middle mb-3">
                            <input id="address" type="text" class="form-control" placeholder="Address"
                                v-model="newTrader.address" @focus="v$.newTrader.address.$reset()" />
                            <label for="address">Address</label>
                            <div v-if="v$.newTrader.address.$error" class="error-message mb-2">
                                <p v-for="error of v$.newTrader.address.$errors" :key="error.$uid">
                                    <strong>{{ error.$message }}</strong>
                                </p>
                            </div>
                        </div>

                        <div class="form-floating fs-middle mb-3">
                            <select class="form-select" id="state" aria-label="State" v-model="newTrader.state"
                                :disabled="states === {}" @change="stateSelected">
                                <option selected value="NA">Select State</option>
                                <option v-for="state in Object.keys(states)" :value="state" :key="state">{{state}}
                                </option>
                            </select>
                            <label for="state">State</label>
                            <div v-if="v$.newTrader.state.$error" class="error-message mb-2">
                                <p v-for="error of v$.newTrader.state.$errors" :key="error.$uid">
                                    <strong>{{ error.$message }}</strong>
                                </p>
                            </div>
                        </div>

                        <div class="form-floating fs-middle mb-3">
                            <select class="form-select" id="district" aria-label="district" v-model="newTrader.district"
                                :disabled="newTrader.state === 'NA'" @change="districtSelected">
                                <option selected value="NA">Select District</option>
                                <option v-for="district in districts" :value="district" :key="district">
                                    {{district}}
                                </option>
                            </select>
                            <label for="district">District</label>
                            <div v-if="v$.newTrader.district.$error" class="error-message mb-2">
                                <p v-for="error of v$.newTrader.district.$errors" :key="error.$uid">
                                    <strong>{{ error.$message }}</strong>
                                </p>
                            </div>
                        </div>

                        <div class="form-floating fs-middle mb-3">
                            <select class="form-select" id="taluka" aria-label="taluka" v-model="newTrader.taluka"
                                :disabled="newTrader.district === 'NA'">
                                <option selected value="NA">Select Taluka</option>
                                <option v-for="taluka in talukas" :value="taluka" :key="taluka">
                                    {{taluka}}
                                </option>
                            </select>
                            <label for="taluka">Taluka</label>
                            <div v-if="v$.newTrader.taluka.$error" class="error-message mb-2">
                                <p v-for="error of v$.newTrader.taluka.$errors" :key="error.$uid">
                                    <strong>{{ error.$message }}</strong>
                                </p>
                            </div>
                        </div>

                        <div class="form-floating fs-bottom mb-3">
                            <input id="pincode" type="text" class="form-control" placeholder="Pincode"
                                v-model="newTrader.pincode" @focus="v$.newTrader.pincode.$reset()" maxlength="6" />
                            <label for="pincode">Pincode</label>
                            <div v-if="v$.newTrader.pincode.$error" class="error-message mb-2">
                                <p v-for="error of v$.newTrader.pincode.$errors" :key="error.$uid">
                                    <strong>{{ error.$message }}</strong>
                                </p>
                            </div>
                        </div>

                        <div v-if="otpEnabled" class="form-floating fs-bottom mb-3">
                            <input id="pincode" type="text" class="form-control" placeholder="otp" v-model="otp"
                                maxlength="6" />
                            <label for="otp">OTP</label>
                        </div>
                    </form>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" @click="closeTrader()">
                        Cancel
                    </button>
                    <button type="button" v-if="!otpEnabled" class="btn btn-primary" @click="saveTrader()">
                        Save
                    </button>
                    <button type="button" v-if="otpEnabled" class="btn btn-primary" @click="verifyOtp()">
                        Verify OTP
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { Modal } from 'bootstrap';
import { helpers, required, numeric, minLength } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import { mapActions, mapState } from "vuex";

const locationSelection = (value) => value !== "NA"
export default {
    name: "AddTraderDialog",
    setup() {
        return {
            v$: useVuelidate()
        }
    },
    data() {
        return {
            addTraderDialog: null,
            districts: [],
            talukas: [],
            otpEnabled: false,
            newTrader: {
                state: "NA",
                district: "NA",
                taluka: "NA",
                firstName: null,
                lastName: null,
                entityName: null,
                phone: null,
                address: null,
                pincode: null,
            },
            traderId: null,
            otp: null,
        };
    },
    validations() {
        return {
            newTrader: {
                firstName: { required },
                lastName: { required },
                entityName: { required },
                address: { required },
                phone: { required, numeric, minLength: minLength(10) },
                pincode: { required, numeric, minLength: minLength(6) },
                district: { locationSelection: helpers.withMessage('Value is required', locationSelection) },
                state: { locationSelection: helpers.withMessage('Value is required', locationSelection) },
                taluka: { locationSelection: helpers.withMessage('Value is required', locationSelection) }
            },
        }
    },
    computed: {
        ...mapState(["states"]),
    },
    props: {
        showHide: {
            type: Boolean,
            default: false
        }
    },
    watch: {
        showHide: function (newVal, oldVal) {
            console.log('Prop changed: ', newVal, ' | was: ', oldVal)
            if (newVal) {
                this.addTraderDialog.show();
            } else {
                this.addTraderDialog.hide();
            }
        }
    },
    methods: {
        ...mapActions(["traderRegStep1", "traderRegStep2", "getStates"]),
        stateSelected() {
            if (this.newTrader.state === "NA") {

                this.newTrader.district = "NA"
                this.newTrader.taluka = "NA"
                this.districts = []
                this.talukas = []
                return;
            } else {
                this.districts = Object.keys(this.states[this.newTrader.state].districts)
            }
        },
        districtSelected() {
            if (this.newTrader.district === "NA") {
                this.talukas = []
                this.newTrader.taluka = "NA"
                return;
            } else {
                this.talukas = this.states[this.newTrader.state].districts[this.newTrader.district].talukas.map((value) => {
                    return value.name;
                })
            }
        },
        closeTrader() {
            this.v$.$reset()
            this.newTrader = {
                state: "NA",
                district: "NA",
                taluka: "NA",
                firstName: null,
                lastName: null,
                entityName: null,
                phone: null,
                address: null,
                pincode: null,
            };
            this.otpEnabled = false;
            this.otp = null;
            this.$emit('closed')
        },
        async saveTrader() {
            const result = await this.v$.$validate()

            if (result) {
                this.newTrader.traderId = this.traderId
                let createResult = await this.traderRegStep1(this.newTrader);
                console.log(createResult)
                if (createResult.success) {
                    this.traderId = createResult.data.trader.uid
                    this.otpEnabled = true;
                }
            }
        },
        async verifyOtp() {
            let createResult = await this.traderRegStep2({
                uid: this.traderId,
                otp: this.otp
            });
            if (createResult.success) {
                this.$emit('created', this.newTrader)
            }
        }
    },
    async mounted() {
        this.addTraderDialog = new Modal(this.$refs.addTraderDialog, {});
        if (this.showHide) {
            this.addTraderDialog.show();
        }
        if (Object.keys(this.states).length === 0) {
            await this.getStates({});
        }
    },
}
</script>
<style scoped>

</style>