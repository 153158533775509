<template>
  <admin-layout>
    <div
      class="
        d-flex
        justify-content-between
        flex-wrap flex-md-nowrap
        align-items-center
        pt-3
        pb-2
        mb-3
        border-bottom
      "
    >
      <h1 class="h2">Dashboard</h1>
      <div class="btn-toolbar mb-2 mb-md-0"></div>
    </div>

    <div class="card text-center mb-3">
      <div class="card-header">Member</div>
      <div class="card-body">
        <p class="card-text">
          Manage Members registered for the FarmServ Infinity program.
        </p>
        <router-link :to="{ name: 'farmers' }" class="btn btn-primary">Continue
          </router-link> 
      </div>
    </div>

    <div class="card text-center mb-3">
      <div class="card-header">Partners</div>

      <div class="card-body">
        <p class="card-text">
          Manage Partners registered for the FarmServ Infinity program.
        </p>
        <router-link :to="{ name: 'retailers' }" class="btn btn-primary">Continue
          </router-link> 
      </div>
    </div>

    <div class="card text-center mb-3">
      <div class="card-header">Distributors</div>

      <div class="card-body">
        <p class="card-text">
          Manage Distributors registered for the FarmServ Infinity program.
        </p>
        <router-link :to="{ name: 'traders' }" class="btn btn-primary">Continue
          </router-link> 
      </div>
    </div>    

    <!-- <div class="card text-center mb-3">
      <div class="card-header">Payments</div>

      <div class="card-body">
        <p class="card-text">Manage Payments from FarmServ to Retailers.</p>
        <router-link :to="{ name: 'payables' }" class="btn btn-primary">Continue
          </router-link>        
      </div>
    </div>

    <div class="card text-center mb-3">
      <div class="card-header">Receipts</div>

      <div class="card-body">
        <p class="card-text">Manage Payments from Farmers to FarmServ.</p>
        <router-link :to="{ name: 'receivables' }" class="btn btn-primary">Continue
          </router-link> 
      </div>
    </div> -->
  </admin-layout>
</template>

<script>
import adminLayout from "@/layouts/admin.vue";
// import { mapActions, mapState } from "vuex";

export default {
  name: "DashboardView",
  components: {
    adminLayout,
  },
  data() {
    return {};
  },
  computed: {},
  methods: {
  },
  mounted() {
  },
};
</script>

<style scoped>
</style>