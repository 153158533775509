<template>
  <admin-layout>
    <div class="
        d-flex
        justify-content-between
        flex-wrap flex-md-nowrap
        align-items-center
        pt-3
        pb-2
        mb-3
        border-bottom
      ">
      <h1 class="h2">Members</h1>
    </div>

    <div class="table-responsive">
      <table class="table table-striped">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Name</th>
            <th scope="col">Phone</th>
            <th scope="col">Location</th>
            <th scope="col">Distributor</th>
            <th scope="col">Membership Limit Used</th>
            <th scope="col">Membership Limit</th>
            <th scope="col">Status</th>
            <th scope="col">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="farmer in farmers" :key="farmer.uid">
            <td>{{ farmer.index }}</td>
            <td>{{ farmer.firstName }} {{ farmer.lastName }}</td>
            <td>{{ farmer.phone }}</td>
            <td>{{ farmer.address }}<br>{{ farmer.taluka }}, {{ farmer.district }}</td>
            <td>{{ farmer.trader.firstName }} {{ farmer.trader.firstName }}<br />
              Entity - {{ farmer.trader.entityName }} <br />
              Ph - {{ farmer.trader.phone }}<br />
              Address - {{ farmer.trader.address }} <br />
              Pin: {{ farmer.trader.pincode }}<br />
              {{ farmer.trader.taluka }}, {{ farmer.trader.district }}
            </td>
            <td>{{ farmer.currentCredit }}</td>
            <td>{{ farmer.creditLimit }}</td>
            <td>{{ getStatus(farmer.step) }}</td>
            <td class=" align-items-stretch">
              <div class="d-flex">
                <router-link class="edit" :to="{
                                                    name: 'farmerDetails',
                                                    params: { fid: farmer.uid },
                                                  }">
                  <i class="material-icons">keyboard_double_arrow_right</i>
                </router-link>
                <i v-if="farmer.step === 2" class="material-icons approve" @click="showConfirmDialog(farmer)">done</i>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div ref="confirmDialog" class="modal fade" data-bs-backdrop="static" id="confirmDialog">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Approve Farmer</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <p>Are you sure you want to approve this farmer?</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" @click="cancelApproval()">Cancel</button>
        <button type="button" class="btn btn-primary"  @click="approveFarmer()">Confirm</button>
      </div>
    </div>
  </div>
</div>
    
    <div class="mt-4">
      <nav aria-label="Page navigation example">
        <ul class="pagination justify-content-center">
          <li class="page-item disabled">
            <a class="page-link" href="#">Previous</a>
          </li>
          <li class="page-item active"><a class="page-link" href="#">1</a></li>
          <li class="page-item"><a class="page-link" href="#">2</a></li>
          <li class="page-item"><a class="page-link" href="#">3</a></li>
          <li class="page-item"><a class="page-link" href="#">Next</a></li>
        </ul>
      </nav>
    </div>
  </admin-layout>
</template>

<script>
import adminLayout from "@/layouts/admin.vue";
import { mapActions, mapState } from "vuex";
import { Modal } from 'bootstrap';

export default {
  name: "FarmersView",
  components: {
    adminLayout,
  },
  data() {
    return {
      approvableFarmer: null,
      confirmDialog:  null,
      deactivationDialog: null,
      limit: 10,
      offset: 0,
    };
  },
  computed: {
    ...mapState(["farmers"]),
  },
  methods: {
    ...mapActions(["getFarmers", "farmerRegStepApprove"]),
    getStatus(step) {
      if (step === 1) {
        return "Pending OTP Verification"
      } else if (step === 2) {
        return "Pending Approval"
      } else if (step === 3) {
        return "Verified"
      }
    },
    cancelApproval() {
      this.approvableFarmer = null;
      this.confirmDialog.hide();
    },
    async approveFarmer() {
      let approveResult = await this.farmerRegStepApprove({
                uid: this.approvableFarmer.uid
        });

        if (approveResult.success) {
          await this.getFarmers({
            limit: this.limit,
            offset: this.offset,
          });
        }
        this.approvableFarmer = null;
        this.confirmDialog.hide();
    },
    showConfirmDialog(farmer) {
      this.approvableFarmer = farmer;
      this.confirmDialog.show();
    }
  },
  async mounted() {
    this.confirmDialog = new Modal(this.$refs.confirmDialog, {});
    
    await this.getFarmers({
      limit: this.limit,
      offset: this.offset,
    });
  },
};
</script>

<style scoped>
table.table td a.edit i {
  font-size: 25px !important;
    color: blue
  }
  
  .approve {
    cursor: pointer;
    font-size: 25px !important;
    color: blue
}

table.table td a.delete i {
  font-size: 16px !important;
}
</style>