<template>
  <admin-layout>
    <div
      class="
        d-flex
        justify-content-between
        flex-wrap flex-md-nowrap
        align-items-center
        pt-3
        pb-2
        mb-3
        border-bottom
      "
    >
      <h1 class="h2">
        <slot name="heading" />
      </h1>
      <div class="btn-group me-2">
        <button type="button" class="btn btn-sm btn-outline-secondary" @click="editTrader()" :disabled="!isLoaded()">
          Edit Distributor Details
        </button>
        <button type="button" class="btn btn-sm btn-outline-secondary" @click="addFarmer()" :disabled="!isLoaded()">
          Add Farmer
        </button>
        <button v-if="trader.active" type="button" class="btn btn-sm btn-outline-secondary" @click="deactivate()"
          :disabled="!isLoaded()">
          Deactivate Distributor
        </button>
        <button v-if="!trader.active" type="button" class="btn btn-sm btn-outline-secondary" @click="activate()"
          :disabled="!isLoaded()">
          Activate Distributor
        </button>
        </div>
        <edit-trader-dialog :showHide="editShowHide" :trader="trader" @updated="traderUpdated" @closed="traderClosed" />
        <active-dialog :showHide="activeShowHide" :userType="'Distributor'" :user="trader" @activation="activeResponse"
          @closed="activeClosed" />
        <deactive-dialog :showHide="deactiveShowHide" :userType="'Distributor'" :user="trader" @deactivation="deactiveResponse"
          @closed="deactiveClosed" />
        <add-farmer-dialog :traderId="traderId" :showHide="farmerShowHide" @created="farmerCreated" @closed="farmerClosed">
        </add-farmer-dialog>
    </div>
    <slot name="content" :traderId="traderId" />
  </admin-layout>
</template>
<script>
import adminLayout from "@/layouts/admin.vue";
import editTraderDialog from '@/components/common/EditTraderDialog.vue'
import activeDialog from '@/components/common/ActivationDialog.vue'
import deactiveDialog from '@/components/common/DeactivationDialog.vue'
import addFarmerDialog from "@/components/common/AddFarmerDialog.vue";
import { mapActions } from 'vuex';
import { useToast } from "vue-toastification";
export default {
  name: "TraderDetailsLayout",
  setup() {
    return {
      toast: useToast()
    }
  },
  data() {
    return {
      traderId: this.$route.params.tid,
      trader: {},
      editShowHide: false,
      activeShowHide: false,
      deactiveShowHide: false,
      farmerShowHide: false
    };
  },
  components: {
    adminLayout,
    editTraderDialog,
    activeDialog,
    deactiveDialog,
    addFarmerDialog,
  },
  async mounted() {
    let response = await this.getTrader({
      traderId: this.traderId
    })
    console.log("====")
    console.log(this.trader)    
    this.trader = response.data.trader
  },
  methods: {
    ...mapActions(['getTrader']),
    traderUpdated() {
      this.$bus.emit('refreshTrader', {
        traderId: this.traderId
      })
      this.toast.success("Distributor updation successful");
      this.editShowHide = false
    },
    traderClosed() {
      this.editShowHide = false
    },
    editTrader() {
      this.editShowHide = true
    },
    deactivate() {
      this.deactiveShowHide = true
    },
    activate() {
      this.activeShowHide = true
    },
    activeClosed() {
      this.activeShowHide = false
    },
    deactiveClosed() {
      this.deactiveShowHide = false
    },
    addFarmer() {
      this.farmerShowHide = true
    },
    farmerClosed() {
      this.farmerShowHide = false
    },
    farmerCreated() {
      this.farmerShowHide = false;
      this.toast.success("Farmer addition successful")
    },
    async activeResponse(response) {
      let msg = response.success ? "Distributor activated" : "Activation Failed"
      if (response.success) {
        let res = await this.getTrader({
          traderId: this.traderId
        })
        this.trader = res.data.trader
        this.$bus.emit('refreshTrader', {
          traderId: this.traderId,
          trader: this.trader
        })
        this.toast.success(msg);
      } else {
        this.toast.error(msg);
      }
      this.activeShowHide = false
    },
    async deactiveResponse(response) {
      let msg = response.success ? "Distributor deactivated" : "Deactivation Failed"
      if (response.success) {
        let res = await this.getTrader({
          traderId: this.traderId
        })
        this.trader = res.data.trader
        this.$bus.emit('refreshTrader', {
          traderId: this.traderId,
          trader: this.trader
        })
        this.toast.success(msg);
      } else {
        this.toast.error(msg);
      }
      this.deactiveShowHide = false
    },
    isLoaded() {
      return Object.keys(this.trader).length === 0 ? false : true
    }
  }
};
</script>
<style scoped>
</style>
