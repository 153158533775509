<template>
  <admin-layout>
    <div
      class="
        d-flex
        justify-content-between
        flex-wrap flex-md-nowrap
        align-items-center
        pt-3
        pb-2
        mb-3
        border-bottom
      "
    >
      <h1 class="h2">Partner</h1>
      <div class="btn-toolbar mb-2 mb-md-0">
        <div class="btn-group me-2">
          <button
            type="button"
            class="btn btn-sm btn-outline-secondary"
            @click="addRetailer()"
          >
            Add Partner
          </button>
        </div>
      </div>
    </div>

    <div class="table-responsive">
      <table class="table table-striped">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Name</th>
            <th scope="col">Entity Name</th>
            <th scope="col">Phone</th>
            <th scope="col">Location</th>
            <th scope="col">Payments Due</th>
            <th scope="col">OTP Verified</th>
            <th scope="col">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="retailer in retailers" :key="retailer.uid">
            <td>{{ retailer.index }}</td>
            <td>{{ retailer.entityName }}</td>
            <td>{{ retailer.firstName }} {{ retailer.lastName }}</td>
            <td>{{ retailer.phone }}</td>
            <td>
              {{ retailer.address }}<br />{{ retailer.taluka }},
              {{ retailer.district }}
            </td>
            <td>{{ retailer.currentCredit }}</td>
            <td>{{ retailer.step === 2 ? "Yes" : "No" }}</td>
            <td>
              <router-link
                class="edit"
                :to="{
                  name: 'retailerDetails',
                  params: { rid: retailer.uid },
                }"
              >
                <i class="material-icons">&#xE254;</i>
              </router-link>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="mt-4">
      <nav aria-label="Page navigation example">
        <ul class="pagination justify-content-center">
          <li class="page-item disabled">
            <a class="page-link" href="#">Previous</a>
          </li>
          <li class="page-item active"><a class="page-link" href="#">1</a></li>
          <li class="page-item"><a class="page-link" href="#">2</a></li>
          <li class="page-item"><a class="page-link" href="#">3</a></li>
          <li class="page-item"><a class="page-link" href="#">Next</a></li>
        </ul>
      </nav>
    </div>
    <add-retailer-dialog
      :showHide="showHide"
      @created="retailerCreated"
      @closed="retailerClosed"
    >
    </add-retailer-dialog>
  </admin-layout>
</template>

<script>
import adminLayout from "@/layouts/admin.vue";
import addRetailerDialog from "@/components/common/AddRetailerDialog.vue";
import { mapActions, mapState } from "vuex";
import { useToast } from "vue-toastification";

export default {
  name: "RetailersView",
  setup() {
    return {
      toast: useToast(),
    };
  },
  components: {
    adminLayout,
    addRetailerDialog,
  },
  data() {
    return {
      deactivationDialog: null,
      limit: 10,
      offset: 0,
      showHide: false,
    };
  },
  computed: {
    ...mapState(["retailers"]),
  },
  methods: {
    ...mapActions(["getRetailers"]),
    closeRetailerDialog() {
      console.log("close Retailer Called");
      this.addRetailerDialog.hide();
    },
    deactivateRetailer() {
      this.deactivationDialog.hide();
    },
    closeDeactivationDialog() {
      this.deactivationDialog.hide();
    },
    showDeactivationDialog(index) {
      console.log(index);
      this.deactivationDialog.show();
    },
    addRetailer() {
      this.showHide = true;
    },
    async retailerCreated() {
      this.showHide = false;
      this.toast.success("Retailer addition successful");
      await this.getRetailers({
        limit: this.limit,
        offset: this.offset,
      });
    },
    retailerClosed() {
      this.showHide = false;
    },
  },
  async mounted() {
    await this.getRetailers({
      limit: this.limit,
      offset: this.offset,
    });
  },
};
</script>

<style scoped>
table.table td a.edit i {
  font-size: 16px !important;
}

table.table td a.delete i {
  font-size: 16px !important;
}
</style>