<template>
  <farmer-details>
    <template #heading>Member Purchases</template>
    <template #content v-if="farmer">
      <div class="table-responsive">
        <table class="table table-striped">
          <thead>
            <tr>
              <th scope="col">Order Time</th>
              <th scope="col">Amount</th>
              <th scope="col">Retailer Name</th>
              <th scope="col">Retailer Entity</th>
              <th scope="col">Phone</th>
              <th scope="col">Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(order, index) in orders" :key="order.id">
              <td>{{ order.orderTime }}</td>
              <td>{{ order.amount }}</td>
              <td>{{ order.retailer.firstName }} {{ order.retailer.lastName }}</td>
              <td>{{ order.retailer.entityName }}</td>
              <td>{{ order.retailer.phone }}</td>
              <td>
                <a class="delete" title="Block" data-toggle="tooltip" @click.prevent="showItems(index)"><i
                    class="material-icons">&#xE254;</i></a>
              </td>
            </tr>
          </tbody>
        </table>
        <items-dialog :showHide="showHideItems" :items="items" @closed="itemsClosed()" />
      </div>
    </template>
  </farmer-details>
</template>

<script>
import farmerDetails from "@/layouts/farmerDetails.vue";
import { mapActions, mapState } from "vuex";
import itemsDialog from '@/components/common/ItemsDialog.vue'

export default {
  name: "FarmerPurchasesView",
  components: {
    farmerDetails,
    itemsDialog
  },
  data() {
    return {
      farmerId: this.$route.params.fid,
      orders: [],
      items: [],
      showHideItems: false
    };
  },
  computed: {
    ...mapState(["farmer"])
  },
  methods: {
    ...mapActions(["getFarmerPurchases"]),
    showItems(index) {
      console.log(index)
      this.items = this.orders[index].items
      this.showHideItems = true
    },
    itemsClosed() {
      this.items = []
      this.showHideItems = false
    }
  },
  async mounted() {
    let response = await this.getFarmerPurchases({
      farmerId: this.farmerId
    })
    if (response.success) {
      this.orders = response.data.orders
    }
  },
};
</script>

<style scoped>
  .item-key {
    min-width: 200px;
  }
  .item-value {
    min-width: 400px;
  }

</style>