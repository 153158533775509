<template>
  <retailer-details>
    <template #heading>Partner Details</template>
    <template #content v-if="retailer">
      <ul class="list-group list-group-horizontal-lg">
        <li class="list-group-item item-key list-group-item-primary">
          First Name
        </li>
        <li class="list-group-item item-value">{{ retailer.firstName }}</li>
      </ul>
      <ul class="list-group list-group-horizontal-lg">
        <li class="list-group-item item-key list-group-item-primary">
          Last Name
        </li>
        <li class="list-group-item item-value">{{ retailer.lastName }}</li>
      </ul>
      <ul class="list-group list-group-horizontal-lg">
        <li class="list-group-item item-key list-group-item-primary">
          Entity Name
        </li>
        <li class="list-group-item item-value">{{ retailer.entityName }}</li>
      </ul>
      <ul class="list-group list-group-horizontal-lg">
        <li class="list-group-item item-key list-group-item-primary">
          GST Number
        </li>
        <li class="list-group-item item-value">{{ retailer.gstNo }}</li>
      </ul>
      <ul class="list-group list-group-horizontal-lg">
        <li class="list-group-item item-key list-group-item-primary">
          Pesticide License Number
        </li>
        <li class="list-group-item item-value">{{ retailer.pesticideLicenseNo }}</li>
        </ul>
        <ul class="list-group list-group-horizontal-lg">
          <li class="list-group-item item-key list-group-item-primary">
            Seed License Number
          </li>
          <li class="list-group-item item-value">{{ retailer.seedLicenseNo }}</li>
        </ul>
        <ul class="list-group list-group-horizontal-lg">
        <li class="list-group-item item-key list-group-item-primary">Phone</li>
        <li class="list-group-item item-value">{{ retailer.phone }}</li>
      </ul>
      <ul class="list-group list-group-horizontal-lg">
        <li class="list-group-item item-key list-group-item-primary">Taluka</li>
        <li class="list-group-item item-value">{{ retailer.taluka }}</li>
      </ul>
      <ul class="list-group list-group-horizontal-lg">
        <li class="list-group-item item-key list-group-item-primary">
          District
        </li>
        <li class="list-group-item item-value">{{ retailer.district }}</li>
      </ul>
      <ul class="list-group list-group-horizontal-lg">
        <li class="list-group-item item-key list-group-item-primary">State</li>
        <li class="list-group-item item-value">{{ retailer.state }}</li>
      </ul>
      <ul class="list-group list-group-horizontal-lg">
        <li class="list-group-item item-key list-group-item-primary">
          Pincode
        </li>
        <li class="list-group-item item-value">{{ retailer.pincode }}</li>
      </ul>
      <ul class="list-group list-group-horizontal-lg">
        <li class="list-group-item item-key list-group-item-primary">
          Active
        </li>
        <li class="list-group-item item-value">{{ retailer.active }}</li>
      </ul>
    </template>
  </retailer-details>
</template>

<script>
import retailerDetails from "@/layouts/retailerDetails.vue";
import { mapActions } from "vuex";

export default {
  name: "RetailerDetailsView",
  components: {
    retailerDetails,
    // Datepicker,
  },
  data() {
    return {
      retailer: {},
      retailerId: this.$route.params.rid
    };
  },
  computed: {
  },
  methods: {
    ...mapActions(["getRetailer"]),
  },
  async mounted() {
    let response = await this.getRetailer({
      retailerId: this.retailerId
    })
    this.retailer = response.data.retailer

    this.$bus.on("refreshRetailer", async (data) => {
      if (this.retailerId === data.retailerId) {
        if (data.retailer) {
          this.retailer = data.retailer
        } else {
          let response = await this.getRetailer({
            retailerId: this.retailerId
          })
          this.retailer = response.data.retailer
        }
      }
    });
  },
};
</script>

<style scoped>
.item-key {
  min-width: 220px;
}

.item-value {
  min-width: 400px;
}
</style>