<template>
  <farmer-details>
    <template #heading>Member Details</template>
    <template #content v-if="farmer">
      <div>
      <ul class="list-group list-group-horizontal-lg">
        <li class="list-group-item item-key list-group-item-primary">First Name</li>
        <li class="list-group-item item-value">{{farmer.firstName}}</li>
      </ul>
      <ul class="list-group list-group-horizontal-lg">
        <li class="list-group-item item-key list-group-item-primary">Last Name</li>
        <li class="list-group-item item-value">{{farmer.lastName}}</li>
      </ul>
      <ul class="list-group list-group-horizontal-lg">
        <li class="list-group-item item-key list-group-item-primary">Phone</li>
        <li class="list-group-item item-value">{{farmer.phone}}</li>
      </ul>
      <ul class="list-group list-group-horizontal-lg">
        <li class="list-group-item item-key list-group-item-primary">Taluka</li>
        <li class="list-group-item item-value">{{farmer.taluka}}</li>
      </ul>
      <ul class="list-group list-group-horizontal-lg">
        <li class="list-group-item item-key list-group-item-primary">District</li>
        <li class="list-group-item item-value">{{farmer.district}}</li>
      </ul>
      <ul class="list-group list-group-horizontal-lg">
        <li class="list-group-item item-key list-group-item-primary">State</li>
        <li class="list-group-item item-value">{{farmer.state}}</li>
      </ul>
      <ul class="list-group list-group-horizontal-lg">
        <li class="list-group-item item-key list-group-item-primary">Pincode</li>
        <li class="list-group-item item-value">{{farmer.pincode}}</li>
      </ul>
      <ul class="list-group list-group-horizontal-lg">
        <li class="list-group-item item-key list-group-item-primary">Membership Limit Used</li>
        <li class="list-group-item item-value">{{farmer.currentCredit}}</li>
      </ul>
      <ul class="list-group list-group-horizontal-lg">
        <li class="list-group-item item-key list-group-item-primary">Membership Limit</li>
        <li class="list-group-item item-value">{{farmer.creditLimit}}</li>
      </ul>
    </div>
    
    <div class="mt-4 mb-4">
      <h6>Distributor Details</h6>
      <ul class="list-group list-group-horizontal-lg">
        <li class="list-group-item item-key list-group-item-primary">First Name</li>
        <li class="list-group-item item-value">{{farmer.trader?.firstName}}</li>
      </ul>
      <ul class="list-group list-group-horizontal-lg">
        <li class="list-group-item item-key list-group-item-primary">Last Name</li>
        <li class="list-group-item item-value">{{farmer.trader?.lastName}}</li>
      </ul>
      <ul class="list-group list-group-horizontal-lg">
        <li class="list-group-item item-key list-group-item-primary">Entity Name</li>
        <li class="list-group-item item-value">{{farmer.trader?.entityName}}</li>
      </ul>
      <ul class="list-group list-group-horizontal-lg">
        <li class="list-group-item item-key list-group-item-primary">Phone</li>
        <li class="list-group-item item-value">{{farmer.trader?.phone}}</li>
      </ul>
      <ul class="list-group list-group-horizontal-lg">
        <li class="list-group-item item-key list-group-item-primary">Taluka</li>
        <li class="list-group-item item-value">{{farmer.trader?.taluka}}</li>
      </ul>
      <ul class="list-group list-group-horizontal-lg">
        <li class="list-group-item item-key list-group-item-primary">District</li>
        <li class="list-group-item item-value">{{farmer.trader?.district}}</li>
      </ul>
      <ul class="list-group list-group-horizontal-lg">
        <li class="list-group-item item-key list-group-item-primary">State</li>
        <li class="list-group-item item-value">{{farmer.trader?.state}}</li>
      </ul>
      <ul class="list-group list-group-horizontal-lg">
        <li class="list-group-item item-key list-group-item-primary">Pincode</li>
        <li class="list-group-item item-value">{{farmer.trader?.pincode}}</li>
      </ul>
    
    </div>
    </template>
  </farmer-details>
</template>

<script>
import farmerDetails from "@/layouts/farmerDetails.vue";
import { mapActions } from "vuex";

export default {
  name: "FarmerDetailsView",
  components: {
    farmerDetails,
  },
  data() {
    return {
      farmer: {},
      farmerId: this.$route.params.fid,
    };
  },
  computed: {
  },
  methods: {
    ...mapActions(["getFarmer"]),
  },
  created() {
    console.log(this.farmerId)
  },
  async mounted() {
    let response = await this.getFarmer({
      farmerId: this.farmerId
    })
    this.farmer = response.data.farmer

    this.$bus.on("refreshFarmer", async (data) => {
      if (this.farmerId === data.farmerId) {
        let response = await this.getFarmer({
          farmerId: this.farmerId
        })
        this.farmer = response.data.farmer
      }
    });
  },
};
</script>

<style scoped>
  .item-key {
    min-width: 200px;
  }
  .item-value {
    min-width: 400px;
  }

</style>